import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { MenuHeader } from '@src/components/menuheader'
import Router from 'next/router'
import { Collapse } from '@src/components/collapse'
import useSitemap from '@src/sitemap'
import LanguageSwitcheroo from '@src/components/LanguageSwitcheroo'
import styles from '../styles/navbar.module.scss'

const Navbar: React.FC<any> = () => {
  const sitemap = useSitemap()

  const goToHome = async () => {
    await Router.push('/')
  }

  const [openNavbar, setOpenNavbar] = useState<boolean>(false)

  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useLayoutEffect(() => {
    handleScroll()
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    console.log(`menu open: ${openNavbar}`)
    if (openNavbar) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [openNavbar])

  // @ts-ignore
  const disclosureRef = useRef<Disclosure | undefined>(undefined)

  // @ts-ignore
  // @ts-ignore
  return (
    <Disclosure ref={disclosureRef} as="nav" className="z-40 fixed w-full">
      {({ open }) => (
        <>
          {
            setOpenNavbar(open)
          }
          <div className="w-full" style={{ background: scrollPosition > 20 ? '#f5f5f5' : 'transparent' }}>
            <div className={styles.topBar}>
              <div className="absolute inset-y-0 right-2 flex items-center xl:hidden">
                <Disclosure.Button className={styles.miniMenu}>
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className={styles.logoContainer}>
                <div className="xl:col-span-2 flex flex-row-reverse">
                  {/* <LanguageSwitcheroo isMobile /> */}
                  <div className="self-center" role="button" onClick={goToHome}>
                    <img className={styles.navbarLogoFullScreen}
                         src="/ColendiInsuranceLogo.png" alt="Icon 3" />
                  </div>
                </div>
                <div className="xl:col-span-8 hidden xl:flex justify-center sm:ml-6 ">

                  <div className="flex space-x-4">
                    {sitemap && sitemap.menus.map((menuButton: any) => {
                      if (menuButton.isOnRight) {
                        return <div />
                      }
                      return <MenuHeader key={`n-${menuButton.text.toLowerCase().replaceAll(' ', '-').substring(0, 20)}`}
                                         content={menuButton.content} text={menuButton.text} isOnRight={false} />
                    })}
                  </div>
                </div>
                <div className={`xl:col-span-2 ${styles.languageSwitcheroo}`}>
                  {/* <LanguageSwitcheroo isMobile={false} /> */}
                </div>
              </div>
              <div className="flex space-x-4 hidden lg:block">
                {sitemap && sitemap.menus.map((menuButton: any) => {
                  if (!menuButton.isOnRight) {
                    return <div />
                  }
                  return <MenuHeader key={`n-${menuButton.text.toLowerCase().replaceAll(' ', '-').substring(0, 20)}`}
                                     content={menuButton.content} text={menuButton.text} isOnRight />
                })}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="xl:hidden bg-white h-[100vh]">
            <div className="">
              <Collapse sitemap={sitemap.menus} isBottom={false} />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>

  )
}

export default Navbar
