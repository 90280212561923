import useTranslation from "@src/hooks/translation"

export default function useSitemap() {

  const translator = useTranslation().sitemap

  return {
    menus: [
      {
        text: translator.homepage,
        url: "/sigorta",
        content: {
          id: "homemenu",
          link: "/sigorta",
          header: "",
          categories: [
            {
              groupId: "ana1",
              linkGroups: [
                {
                  header: "",
                  links: [
                    {
                      title: translator.about,
                      url: "/sigorta"
                    },
                    {
                      title: translator.contact,
                      url: "/sigorta?p=bize-ulasin"
                    },
                    {
                      title: translator.join,
                      url: "/sigorta?p=ekibe-katil"
                    }
                  ]
                }
              ]
            }
          ]
        }
      },
      {
        text: translator.products,
        url: "/?p=urun",
        content: {
          id: "productsmenu",
          link: "/?p=urun",
          header: "",
          categories: [
            {
              groupId: "products1",
              linkGroups: [
                {
                  header: "",
                  links: [
                    {
                      title: translator.pet,
                      url: "/?p=urun"
                    },
                    {
                      title: translator.travel,
                      url: "/?p=urun"
                    },
                    {
                      title: translator.protectBill,
                      url: "/?p=urun"
                    },
                    {
                      title: translator.earthquake,
                      url: "/?p=urun"
                    }
                  ]
                }
              ]
            },
            {
              groupId: "products2",
              linkGroups: [
                {
                  header: "",
                  links: [
                    {
                      title: translator.screenCrack,
                      url: "/?p=urun2"
                    },
                    {
                      title: translator.electronic,
                      url: "/?p=urun2"
                    },
                    {
                      title: translator.health,
                      url: "/?p=urun2"
                    },
                    {
                      title: translator.traffic,
                      url: "/?p=urun2"
                    }
                  ]
                }
              ]
            }
          ]
        }
      },
      {
        text: translator.integration,
        url: "/?p=entegrasyon",
        content: {
          id: "integrationmenu",
          link: "/?p=entegrasyon",
          header: "",
          categories: [
            {
              groupId: "integartion1",
              linkGroups: [
                {
                  header: "",
                  links: [
                    {
                      title: translator.embedded,
                      url: "/?p=entegrasyon"
                    },
                    {
                      title: translator.affinity,
                      url: "/?p=entegrasyon"
                    },
                    {
                      title: translator.lead,
                      url: "/?p=entegrasyon"
                    }
                  ]
                }
              ]
            }
          ]
        }
      },
      {
        text: translator.partners,
        url: "/?p=partner",
        content: {
          id: "partnersmenu",
          link: "/?p=partner",
          header: "",
          categories: [
            {
              groupId: "partners1",
              linkGroups: [
                {
                  header: "",
                  links: [
                    {
                      title: translator.technology,
                      url: "/?p=partner"
                    }
                  ]
                },{
                  header: "",
                  links: [
                    {
                      title: translator.service,
                      url: "/?p=partner"
                    }
                  ]
                }
              ]
            },
            {
              groupId: "partners2",
              linkGroups: [
                {
                  header: "",
                  links: [
                    {
                      title: translator.retail,
                      url: "/?p=partner"
                    }
                  ]
                }
              ]
            }
          ]
        }
      },
      {
        text: translator.agreedInsuranceCompanies,
        url: "/?p=sigorta",
        content: {
          id: "firmsmenu",
          link: "/?p=sigorta",
          header: "",
          categories: [
            {
              groupId: "firms1",
              linkGroups: [
                {
                  header: "",
                  links: [
                    {
                      title: "Türkiye Sigorta",
                      url: "/?p=sigorta"
                    },
                    {
                      title: "Anadolu Sigorta",
                      url: "/?p=sigorta"
                    },
                    {
                      title: "AXA Sigorta",
                      url: "/?p=sigorta"
                    }
                  ]
                }
              ]
            },
            {
              groupId: "partners2",
              linkGroups: [
                {
                  header: "",
                  links: [
                    {
                      title: "Ak Sigorta",
                      url: "/?p=sigorta"
                    },
                    {
                      title: "Met Life",
                      url: "/?p=sigorta"
                    },{
                      title: "Gulf Sigorta",
                      url: "/?p=sigorta"
                    }
                  ]
                }
              ]
            }
          ]
        }
      }
    ]
  }
}
