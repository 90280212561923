import { Collapse } from '@src/components/collapse'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import styles from '@src/styles/footer.module.scss'
import { SocialMediaLinks } from '@src/pages/_app'
import { useContext, useRef } from 'react'
import useSitemap from '@src/sitemap'
import useTranslation from '@src/hooks/translation'
import { LangContext } from '@src/context/LanguageContext'
import SvgFacebook from '../sigorta_face'
import SvgTwitter from '../sigorta_twitter'
import SvgYoutube from '../sigorta_youtube'
import SvgInstagram from '../sigorta_insta'
import SvgLinkedin from '../sigorta_linkedin'

interface HomePageFooterProps {
  links?: SocialMediaLinks
}

const HomepageFooter: React.FC<HomePageFooterProps> = () => {
  const sitemap = useSitemap().menus
  const commonTranslation = useTranslation().common
  const links: SocialMediaLinks = {
    facebookLink: 'https://www.facebook.com/profile.php?id=100090578756400',
    instagramLink: 'https://www.instagram.com/colendisigorta/',
    twitterLink: 'https://twitter.com/ColendiSigorta',
    youtubeLink: 'https://www.youtube.com/channel/UC96tKOaw-qwqcSWS9iJnCqQ',
    linkedInLink: 'https://www.linkedin.com/company/colendi-sigorta/?viewAsMember=true',
  }

  const footerRef = useRef() as React.MutableRefObject<HTMLDivElement>

  const { lang } = useContext(LangContext)

  // @ts-ignore
  library.add(fab)

  const goTo = (link: string, linkName: string) => {
    try {
      // @ts-ignore
      window.gtag('event', `web_${linkName}_click`)
    } catch (e) {
      console.log(e)
    }

    window.open(link, '_blank')
  }

  return (
    <>
      <div ref={footerRef} className={styles.footerBody}>
        <div className="flex flex-col lg:flex-row lg:w-full lg:max-w-[80%]">
          <div className="flex m-auto lg:m-0 lg:ml-8 flex-col justify-center lg:justify-start items-center lg:w-1/6 basis-[min-content]">
            <img
              src="/ColendiInsuranceLogoWhite.png"
              className="w-[200px] object-contain h-auto max-w-none pt-2"
              alt="colendi"
            />
            {links && (
              <div className="flex gap-6 md:gap-0 md:flex-col items-center pt-[60px]">
                <div className="grid grid-cols-3 gap-6 h-8 w-auto">
                  <div
                    role="button"
                    onClick={() => {
                      goTo(links.facebookLink, 'facebook')
                    }}
                    className={`${styles.footerIconContainer}`}
                  >
                    <SvgFacebook />
                  </div>
                  <div
                    role="button"
                    onClick={() => {
                      goTo(links.twitterLink, 'twitter')
                    }}
                    className={`${styles.footerIconContainer}`}
                  >
                    <SvgTwitter />
                  </div>
                  <div
                    role="button"
                    onClick={() => {
                      goTo(links.youtubeLink, 'youtube')
                    }}
                    className={`${styles.footerIconContainer}`}
                  >
                    <SvgYoutube />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6 h-8 w-auto md:mt-[25px]">
                  <div
                    role="button"
                    onClick={() => {
                      goTo(links.instagramLink, 'instagram')
                    }}
                    className={`${styles.footerIconContainer}`}
                  >
                    <SvgInstagram />
                  </div>
                  <div
                    role="button"
                    onClick={() => {
                      goTo(links.linkedInLink, 'linkedin')
                    }}
                    className={`${styles.footerIconContainer}`}
                  >
                    <SvgLinkedin />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex-2 pt-8 md:pt-0 md:pl-8">
            <div>
              <div className={styles.footerLinksContainer}>
                {sitemap.map((menu) => {
                  const identifier = menu.text.toLowerCase()
                  const classNames = 'flex-1 flex flex-col'

                  return (
                    <div key={`fc-${identifier}`} className="flex-1 px-1 flex flex-col ml-0">
                      <div
                        role="button"
                        className="pb-4 xxl:pb-8"
                        onClick={() => {
                          if (typeof window !== undefined) {
                            window.location.href = menu.url
                          }
                        }}
                      >
                        <a className={`${styles.headerText}`}>{menu.text}</a>
                      </div>
                      <div key={`fr-${identifier}`} className={`${classNames} pb-[11px]`}>
                        {menu.content.categories?.map((linkCategory: any) => {
                          return (
                            <div
                              key={`fg-${linkCategory.groupId}`}
                              className="flex flex-col"
                              style={{ paddingRight: '2vw' }}
                            >
                              {linkCategory.linkGroups.map((linkGroup: any) => {
                                return (
                                  <div key={`fh-${linkGroup.header}`}>
                                    <div
                                      className="text-white"
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '600',
                                      }}
                                    >
                                      {linkGroup.link === undefined && (
                                        <p className="hover:underline">{linkGroup.header}</p>
                                      )}
                                      {linkGroup.link !== undefined && (
                                        <a href={linkGroup.link!} className="hover:underline">
                                          {linkGroup.header}
                                        </a>
                                      )}
                                    </div>
                                    {linkGroup.links &&
                                      linkGroup.links.map((link: any) => {
                                        return (
                                          <div
                                            key={`fl-${link.title.toLocaleLowerCase().replaceAll(' ', '-')}`}
                                            className={styles.linkText}
                                          >
                                            <div
                                              role="button"
                                              onClick={() => {
                                                if (typeof window !== undefined) {
                                                  window.location.href = link.url
                                                }
                                              }}
                                            >
                                              {link.title}
                                            </div>
                                          </div>
                                        )
                                      })}
                                  </div>
                                )
                              })}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="block lg:hidden">
                <Collapse sitemap={sitemap} isBottom />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.disclaimerBody} style={{ fontSize: '13px' }}>
        <div className="w-full max-w-[80%] flex flex-col md:flex-row gap-4 md:gap-1 justify-between">
          <p>{commonTranslation.colendiYear}</p>
          <div className="flex">
            <a
              onClick={(event: any) => {
                // @ts-ignore
                window.gtag('event', 'web_click_privacy_policy')
              }}
              href={
                lang === 'tr'
                  ? '/Colendi Sigorta_Gizlilik Politikası_22062022.pdf'
                  : '/Colendi Sigorta - Privacy Policy.pdf'
              }
              target="_blank"
              className="text-white font-[14px] font-normal"
            >
              {commonTranslation.nda}
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomepageFooter
