function SvgYoutube() {
  return (
    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4934 6.5122C19.2101 6.70475 19.7771 7.27172 19.9696 7.98845C20.3226 9.29354 20.3333 12 20.3333 12C20.3333 12 20.3333 14.7172 19.9803 16.0116C19.7878 16.7283 19.2208 17.2952 18.5041 17.4878C17.2097 17.8408 12 17.8408 12 17.8408C12 17.8408 6.79032 17.8408 5.49593 17.4878C4.77919 17.2952 4.21223 16.7283 4.01967 16.0116C3.66666 14.7065 3.66666 12 3.66666 12C3.66666 12 3.66666 9.29354 4.00898 7.99915C4.20153 7.28241 4.7685 6.71545 5.48523 6.5229C6.77962 6.16987 11.9893 6.15918 11.9893 6.15918C11.9893 6.15918 17.199 6.15918 18.4934 6.5122ZM14.653 12L10.3312 14.5032V9.49679L14.653 12Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgYoutube
