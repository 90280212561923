import en from '@src/lang/en'
import tr from '@src/lang/tr'
import { useContext, useEffect, useState } from 'react'
import { LangContext } from '@src/context/LanguageContext'

export default function useTranslation(): any {
  const [translation, setTranslation] = useState<any>(en)

  const { lang, SetLang } = useContext(LangContext)

  useEffect(() => {
    let userLang=lang
    if (userLang==='') {
      userLang = navigator.language
    }
    if (userLang === 'tr' || userLang === 'tr-TR') {
      setTranslation(tr)
    } else {
      setTranslation(en)
    }
  }, [lang])


  return translation
}

