import { ArrowRightIcon } from '@heroicons/react/outline'
import { Menu } from '@src/interfaces/sitemap'
import { MenuColumn } from '@src/components/menucolumn'
import React, { useEffect, useRef } from 'react'
import styles from '../styles/test.module.scss'
import AppContext from '../../AppContext'

interface MenuPanelProps {
  options: Menu;
  isOnRight: boolean
}

export const Menupanel: React.FC<MenuPanelProps> = ({ options, isOnRight }) => {

  const appContext = React.useContext(AppContext)

  const panelRef = useRef<HTMLElement | null>(null) as React.MutableRefObject<HTMLInputElement>

  useEffect(() => {
    const e: HTMLElement | null = document.getElementById(options.id)

    if (e !== null && panelRef.current != null) {
      const l: number = isOnRight ? e.getBoundingClientRect().right : e.getBoundingClientRect().left
      const pleft = panelRef.current.getBoundingClientRect().left
      const pwidth = panelRef.current.getBoundingClientRect().width

      if (isOnRight) {
        panelRef.current.style.transform = `translateX(${l - pwidth}px)`
      } else {
        panelRef.current.style.transform = `translateX(${l}px)`
      }

      const overflown: boolean = (pleft + pwidth) > window.innerWidth
      if (overflown && !isOnRight) {
        console.log(`${options.id} has overflown`)
        const diff: number = (window.innerWidth / 2) - ((pwidth) / 2)
        panelRef.current.style.transform = `translateX(${diff}px)`
      }
    }

  }, [panelRef, appContext!.navbarMenuId])

  return (
    <div className={`bg-transparent absolute w-full py-4 ${appContext?.navbarMenuId !== options.id ? styles.menuhidden : styles.menupanelbig}`}>
      <div ref={panelRef} className={styles.menupanelbetween}>
        <div className="z-40 bg-white rounded-xl p-1 gap-1 my-2">
          {options.categories &&
            <div className={`indirectgridcol${options.categories?.length ?? 0}`}>
              {options.header &&
                <div className={styles.menuHeader}>
                  <span className="text-2xl font-bold">{options.header}</span>
                  <ArrowRightIcon className="ml-2 h-6 w-5 self-center" aria-hidden="true" />
                </div>
              }
              <div className="flex px-4 pt-4 justify-around">
                {options.categories.map((category,index) => {
                  return (
                    <div key={`m-${category.groupId}`} style={{borderLeft: index===1?'1px solid #333333':'0px', padding:((options.categories?.length??0)>0)?'0rem 1rem':'0rem'}}>
                      <MenuColumn linkGroups={category.linkGroups} />
                    </div>
                  )
                })}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}