export default {
  home: {
    pageTitle: "Colendi Sigorta",
    preTitle: "Yeni Nesil",
    titleColored: "Sigorta Hizmetlerini",
    postTitle: "Anında Sunmaya Başlayın!",
    subTitle: "Colendi Sigorta'nın hızlı çözümleriyle müşterilerinize en <br/> uygun sigorta seçeneklerini sunun.",
    startButton: "Hemen Başlayalım",
    step0: {
      title: "Sizin Platformunuz, Sizin Müşteriniz Colendi Sigorta Uzmanlığı",
      text: "Colendi Sigorta ile 3 adımda müşterilerinize en uygun sigorta ürünlerini ulaştırabilirsiniz."
    },
    step1: {
      title: "1) İhtiyaçlarınızı Belirleyelim",
      text: "İhtiyaçlarınız sektöre ve platforma göre farklılaşabilir. Colendi Sigorta olarak sektör ve platform analizi yapıyor ve müşterilerinizin ihtiyaçlarını belirliyoruz."
    },
    step2: {
      title: "2) Size Uygun Ürünü Seçelim",
      text: "Uzman ekibimizin analiz sonuçlarına göre platformunuza uygun ürünleri sizin için seçiyoruz.",
      card1: {
        title: "Evcil Hayvan",
        text: "Patili dostlarımızın sağlığının ne kadar önemli olduğunu biliyoruz. Evcil Hayvan Sigortası, kedi ve köpeklerin acil durum ve belirli hastalıklara yönelik tedavi giderlerini karşılar"
      },
      card2: {
        title: "Seyahat Sağlık", text: "Seyahat sağlık sigortası, seyahatlerde yaşanabilecek ani sağlık sorunlarına karşı sizi güvence altına alır."
      },
      card3: {
        title: "Fatura Koruma",
        text: "Bu sigorta hizmeti ile istem dışı işsizlik durumlarında son ödenen fatura tutarı 6 ay boyunca güvence altına alınır. Böylece müşterileriniz ani gelişebilecek mali sıkıntılara karşı korunur."
      },
      card4: {
        title: "Ekran Kırılması",
        text: "Ekran kırılması sigortası ile cihaz ekranının kırılması durumunda tamirat masrafları karşılanır ve bu kapsamda müşterilerinizin cihazları teminat altına alınır."
      },
      card5: {
        title: "Elektronik Cihaz",
        text: "Elektronik Cihaz Sigortası ile cihazınızın kazaen kırılması, voltaj değişimi nedeniyle oluşan hasarları, su geçirmesi ve çalınması gibi durumlarda müşterinizin masrafları karşılanır."
      },
      card6: {
        title: "Acil Sağlık",
        text: "Beklenmedik şekilde gelişen ve acil sağlık hizmeti alınması gereken durumlarda tedavi masrafları kapsam altına alınır."
      },
      card7: {
        title: "Deprem",
        text: "Olası bir depremde evinizde meydana gelebilecek zararlara karşı sizi teminat altına alır. Teminat, binanızın kısmî veya tamamen hasar aldığı durumları kapsar."
      },
      card8: {
        title: "Trafik",
        text: "Trafik Sigortası, aracınız ile yaşayabileceğiniz kazalar sonucunda başka araç veya üçüncü kişilerde oluşabilecek zararları kapsam altına alır."
      }
    },
    step3: {
      title: "3) Entegrasyon Modelini Belirleyelim",
      text: "Hızlı ve satışa dönüşümü en yüksek olacak şekilde platformunuza en uygun entegrasyon modelini belirleyerek sigorta sunabilmenizi sağlıyoruz.",
      card1: {
        title: "Embedded Sigorta",
        text: "Sigorta ürünlerinin, platformunuzun satış süreçlerine gömüldüğü ya da direkt ürünle entegre şekilde satışının yapıldığı sigortacılık yöntemidir. Satışa dönüşüm oranı en yüksek yöntem olsa da sigortacılık regülasyonu konusunda önemli uzmanlıklar gerektirir."
      },
      card2: {
        title: "Affinity Projeleri",
        text: "Platformun sunduğu hizmetlerle ya da platform kullanıcılarının ortak özellik ve beklentilerine cevap verebilmek adına, seçilen ürünlerin web yönlendirmesi ile satılmasıdır. Colendi Sigorta sayesinde entegrasyon ihtiyacı barındırmaz, ancak ürün seçiminde uzmanlık gerektirir."
      },
      card3: {
        title: "Lead Üretimi",
        text: "Sunulabilecek en hızlı yöntem olmasının yanı sıra platformunuzda sigorta satışının test edilebilmesi için de en uygun yöntemdir. Toplanan leadler, sigorta uzmanlarınca aranarak satışa dönüşmesi sağlanır. Satışı zor ve uzmanlık gerektiren sigorta ürünlerinde kullanılması daha uygundur."
      }
    },
    partners: "Partnerlerimiz",
    partner1: "Teknoloji",
    partner2: "Perakende",
    partner3: "Hizmet",
    agreedInsuranceCompanies: "Anlaşmalı Sigorta Şirketleri"
  },
  insurance: {
    about: "Hakkımızda",
    text: "2021 yılında kurulan Colendi Sigorta, sigortacılık ihtiyaçlarına erişimi kolaylaştıran ve yeni nesil sigorta ürünlerini platformlar aracılığıyla daha geniş kitlelere erişilebilir kılan bir insuretech hizmet sağlayıcısıdır. T.C Hazine ve Maliye Bakanlığı Sigortacılık Genel Müdürlüğü bünyesinden Acentelik lisansı bulunan ve TOBB nezdinde sigorta aracılık levhasına kayıtlı olan Colendi Sigorta, dijital platformların, kullanıcılarına uçtan uca sigorta çözümleri sunabilmelerini sağlayacak teknoloji alt yapıları geliştiriyor. Partner kurumlar için en uygun ürünleri sigortacılık alanındaki deneyimli kadrosu ile tespit ederek, en kolay ve hızlı şekilde platformlarda sigorta ürünler ve hizmetlerinin konumlanmasını sağlıyor.<br /><br /> Colendi Sigorta, bir Colendi Yapay Zeka ve Büyük Veri Teknoloji Hizmetleri A.Ş. iştirakidir.",
    join: "Katıl",
    joinTeam: "Ekibe Katılın",
    takeYourPlace: "Yarının sigorta dünyasında yerinizi alın!"
  },
  contact: {
    contactUs: "Bize Ulaşın",
    formSent: "Formunuz gönderildi, en kısa sürede uzman ekibimiz sizinle iletişime geçecektir.",
    company: "Kurum",
    badCompany: "Hatalı kurum",
    name: "Ad Soyad",
    badName: "Hatalı ad soyad",
    email: "E-posta",
    badEmail: "Hatalı e-posta",
    phone: "Telefon",
    badPhone: "Hatalı telefon",
    message: "Mesaj",
    beforeText: "Colendi Sigorta Acenteliği Anonim Şirketi Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni’ne ",
    hereText: "buradan",
    afterText: " ulaşabilirsiniz.",
    send: "Gönder",
    ok: "Tamam"
  },
  cookies: {
    cookie1: {
      text: "Bu tür çerezler, web sitesinin bazı bölümlerinin doğru çalışmasını sağlar. Bu çerezler internet sitesinin çalışması ve tüm özelliklerinden yararlanmanız amacıyla –teknik bakımdan- gerekli olan çerezlerdir, bu nedenle kullanıcı tarayıcısındaki ayarları değiştirmediğiniz müddetçe bu çerezler her zaman aktif olacaktır.",
      title: "Teknik Çerezler"
    },
    cookie2: {
      text: "Bu çerezler, kullanıcıların ziyaret ettiği sayfalar, yaş, cinsiyet ve pazarlama kampanyaları için ilgi alanları, harcanan zaman, menşe trafiğinin kökenleri, coğrafi köken, tıklama sayısı, içerik paylaşımı sayısı, sevilen sayfalar ve içerik gibi web sitesinin anonim olarak kullanımı hakkında bilgi toplamak için kullanılır. Bu çerezler, yukarıda açıklandığı gibi sitenin dışındaki üçüncü taraf alan adlarından gönderilir.",
      title: "Üçüncü Taraf Hizmetleri İçin Analiz Çerezleri"
    },
    cookie3: {
      text: "Bu tür çerezler, web sitesi içeriğini paylaşmak veya üçüncü taraf yazılım hizmetlerinin kullanımı için (harita oluşturma yazılımı gibi ek hizmetler sunan yazılımlar) sosyal ağlarda ifade edilen simgeler ve tercihler gibi web sitesinin sayfalarında üçüncü taraflarca geliştirilen özellikleri entegre eder. Bu çerezler, kullanıcının görüntülediği web sitesinden değil, üçüncü taraf alan adlarından gönderilir.",
      title: "Üçüncü Taraf Yazılımların Ürünlerini ve İşlevlerini Entegre Eden Çerezler"
    },
    cookie4: {
      text: "Bu çerezler, çevrimiçi ve kullanıcı tarafından belirlenen tercihlere göre hedeflenen reklam mesajlarını göndermek amacıyla (doğrudan pazarlama etkinliği amacıyla) kullanıcı profilleri oluşturmak için gereklidir.",
      title: "Profil Oluşturma Çerezleri"
    },
    cookie5: {
      text: "Bu çerezler, özellikle yeniden pazarlama yapmak amacıyla üçüncü şahıslara sitemizi ziyaretiniz ile ilgili bazı bilgileri göndermek amacıyla kullanılmaktadır.",
      title: "Hedef/Reklam Çerezleri"
    },
    reason: {
      start: "İnternet sitemizde teknik ve üçüncü taraf çerezler kullanılmaktadır. Teknik çerezler sitemizin çalışması için zorunlu olan çerezlerdir. Kullandığımız çerezler ve gizlilik ile ilgili daha fazla bilgi için ",
      link1: "Çerez Politikamızı",
      and: " ve ",
      link2: "Gizlilik politikamızı",
      end: " inceleyebilirsiniz"
    },
    acceptAll: "Tümünü Kabul Et",
    acceptSelected: "Seçilileri Kabul Et",
    close: "Çerez ayarlarını kapat",
    open: "Çerez ayarlarını aç"
  },
  sitemap: {
    homepage: "Colendi Sigorta",
    about: "Hakkımızda",
    contact: "Bize Ulaşın",
    join: "Ekibe Katılın",
    products: "Ürünler",
    pet: "Evcil Hayvan",
    travel: "Seyahat Sağlık",
    protectBill: "Fatura Koruma",
    earthquake: "Deprem",
    traffic: "Trafik",
    screenCrack: "Ekran Kırılması",
    electronic: "Elektronik Cihaz",
    health: "Acil Sağlık",
    integration: "Entegrasyon",
    embedded: "Embedded Sigorta",
    affinity: "Affinity Projeleri",
    lead: "Lead Üretimi",
    partners: "Partnerlerimiz",
    technology: "Teknoloji",
    retail: "Perakende",
    service: "Hizmet",
    agreedInsuranceCompanies: "Anlaşmalı Sigorta Şirketleri"
  },
  common: {
    colendiYear: "© 2022 Colendi Sigorta Acenteliği A.Ş.",
    nda: "Gizlilik Politikasi"
  }
}
