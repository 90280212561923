import { MenuButton } from '@src/interfaces/sitemap'
import styles from '@src/styles/footer.module.scss'

interface Props {
  children?: any,
  isBottom: boolean,
  sitemap: any
}

export const Collapse: React.FC<Props> = (props) => {

  function handleClick(menu: MenuButton) {
    if ((menu.content.categories?.length ?? 0) === 0) {
      window.location.href = menu.content.link as string
    }
  }

  function handleUrlClick(link: string) {
    window.location.href = link
  }

  return (
    <>
      {
        props.sitemap.map((menu:any) => {
          return <div onClick={() => {
            handleClick(menu)
          }} key={`fc__${menu.text.substring(0, 20).toLowerCase().replaceAll(' ', '-')}`}
                      className={`show collapse rounded-box border-base-500 ${(menu.content.categories?.length ?? 0) > 0 ? 'collapse-arrow' : ''} my-1`}>
            <input type="checkbox" className="self-center" style={{ minHeight: '2.8rem', paddingTop: '0px', paddingBottom: '0px' }} />
            <div className={`collapse-title ${props.isBottom ? styles.collapseWhite : styles.collapseBlack}`}
                 style={{ minHeight: 'unset', paddingTop: '0px', paddingBottom: '0px' }}>
              <div className="self-end">
                {menu.text}
              </div>
            </div>
            <div className="collapse-content">
              {
                menu.content.categories?.map((linkCategory:any) => {
                  return linkCategory.linkGroups.map((linkGroup:any) => {
                    return <>
                      <div className="ml-2 mt-2 text-md font-medium">
                        <div style={{ height: '12px' }}></div>
                        {linkGroup.links &&
                          linkGroup.links.map((link:any) => {
                            return <div onClick={(e) => handleUrlClick(link.url)}
                                        key={`fc${link.title.substring(0, 20).toLowerCase().replaceAll(' ', '-')}`}
                                        className="text-base py-2" style={{ color: props.isBottom ? '#ddd' : '#555555' }}>
                              <a
                                key={`fca${link.title.substring(0, 20).toLocaleLowerCase().replaceAll(' ', '-')}`}>{link.title}</a>
                            </div>
                          })
                        }
                      </div>
                    </>
                  })
                })
              }
            </div>
          </div>
        })
      }
    </>
  )

}
