import { LinkGroup } from '@src/interfaces/sitemap'
import { ChevronRightIcon } from '@heroicons/react/outline'
import styles from '../styles/test.module.scss'
import { useMediaQuery } from '@mui/material'

interface MenuColumnProps {
  linkGroups: LinkGroup[];
}

export const MenuColumn: React.FC<MenuColumnProps> = ({ linkGroups }) => {
  const isDesktop = useMediaQuery('(min-width: 768px)')
  const columnElements = linkGroups.map((linkGroup) => {
      if (linkGroup.hideInDesktopMode && isDesktop) {
        return <div />
      }
      const identifier = linkGroup.id ? linkGroup.id : linkGroup.header
      // if (identifier === undefined) {
      //   console.log(`error at: ${JSON.stringify(linkGroup)}`)
      //   console.log(`header: ${linkGroup.header} id: ${linkGroup.id}`)
      // }

      function goTo(e: any, link: string) {
        if (link !== '') {
          e.preventDefault()
          e.stopPropagation()
          window.location.href = link
        }
        return false
      }

      return (
        <div key={identifier.toLowerCase().replaceAll(' ', '-').substring(0, 20)} className="pb-4">
          {linkGroup.link &&
            <div role="button" className={styles.menuHeaderLink} onClick={(e) => goTo(e, linkGroup.link ?? '')}>
              <a
                href={linkGroup.link ?? '#'}
                className={`font-bold pl-1 ${linkGroup.link ? styles.clickableHeader : ''}`}>{linkGroup.header}</a>
              <div className="flex pr-2">
                <ChevronRightIcon className={styles.menuitemArrow} />
              </div>
            </div>
          }
          {
            linkGroup.links?.map((link) => {
              return <div role="button" onClick={(e) => goTo(e, link.url)} key={link.title.toLowerCase().replaceAll(' ', '-').substring(0, 20)}
                          className={styles.menuLink}>
                <div className="flex">
                  <a href={link.url} className={`${styles.clickableSubHeader}`}>{link.title}</a>
                </div>
              </div>
            })
          }
        </div>
      )
    }
  )

  return (
    <div>
      {columnElements}
    </div>
  )

}