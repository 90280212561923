function SvgFacebook() {
  return (
    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="5" fill="white" />
      <path
        d="M20 12.0485C20 7.6032 16.4187 4 12 4C7.58133 4 4 7.6032 4 12.0485C4 16.0667 6.9248 19.3963 10.7499 20V14.3755H8.71893V12.048H10.7499V10.2752C10.7499 8.25813 11.944 7.14347 13.7717 7.14347C14.6464 7.14347 15.5627 7.3008 15.5627 7.3008V9.2816H14.5531C13.5595 9.2816 13.2501 9.9024 13.2501 10.5392V12.0485H15.4688L15.1141 14.3749H13.2501V20C17.0752 19.3963 20 16.0667 20 12.0485Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgFacebook
