function SvgTwitter() {
  return (
    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="5" fill="white" />
      <path d="M18.627 8.60007C18.6372 8.747 18.6372 8.89392 18.6372 9.04221C18.6372 13.5605 15.1976 18.7714 8.90804 18.7714V18.7687C7.0501 18.7714 5.23074 18.2392 3.66666 17.2357C3.93682 17.2682 4.20833 17.2845 4.48052 17.2851C6.02024 17.2865 7.51594 16.7699 8.72726 15.8186C7.26406 15.7908 5.98096 14.8368 5.53273 13.444C6.04529 13.5429 6.57342 13.5225 7.0765 13.3851C5.48127 13.0628 4.33359 11.6612 4.33359 10.0335C4.33359 10.0186 4.33359 10.0044 4.33359 9.99014C4.80891 10.2549 5.34111 10.4018 5.88549 10.4181C4.38302 9.41393 3.91989 7.41515 4.8272 5.85242C6.56326 7.98865 9.12471 9.28732 11.8744 9.42477C11.5988 8.23714 11.9753 6.99264 12.8636 6.15779C14.2408 4.86318 16.4069 4.92954 17.7015 6.30607C18.4673 6.15508 19.2012 5.87409 19.8729 5.47595C19.6176 6.26748 19.0834 6.93983 18.3698 7.36708C19.0475 7.28718 19.7097 7.10572 20.3333 6.82879C19.8743 7.51672 19.296 8.11594 18.627 8.60007Z" fill="black"/>
    </svg>
  )
}

export default SvgTwitter
