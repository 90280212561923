import React, { createContext, ReactNode, useEffect, useState } from 'react'
import tr from '@src/lang/tr'
import en from '@src/lang/en'

type Props = {
  lang: string,
  SetLang: (val: string) => void,
}
type ChildrenProviderProps = {
  children: ReactNode;
};

export const defaultProps: Props = {
  lang: 'tr',
  SetLang: () => {
  }
}
export const LangContext = createContext(defaultProps)

export const LangProvider = ({ children }: ChildrenProviderProps) => {

  const [lang, setLang] = useState('tr')

  const SetLang = (val: string) => {
    setLang(val)
    localStorage.setItem('lang', val)
  }

  useEffect(() => {
    const Lang = localStorage.getItem('lang')
    if (Lang == null || Lang === '') {
      const userLang = navigator.language
      if (userLang === 'tr' || userLang === 'tr-TR') {
        setLang('tr')
      } else {
        setLang('en')
      }
    }else{
      setLang(Lang)
    }
  }, [])

  return (

    <LangContext.Provider value={{
      lang, SetLang
    }}>
      {children}
    </LangContext.Provider>
  )
}


