import 'intl-tel-input/build/css/intlTelInput.css'
import '@src/styles/countrycodepicker.css'
import '@src/styles/tailwind.scss'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { AppProps } from 'next/app'
import NavBar from '@src/components/navbar'
import { Router } from 'next/router'
import { Dialog } from '@headlessui/react'
import styles from '@src/styles/footer.module.scss'
import testStyles from '@src/styles/test.module.scss'
import useTranslation from '@src/hooks/translation'
import HomepageFooter from '@src/components/homepageFooter'
import AppContext, { AppContextInterface } from '../../AppContext'
import { GTMPageView } from '../utils/gtm'
import Script from 'next/script'
import { LangContext, LangProvider } from '@src/context/LanguageContext'

export interface SocialMediaLinks {
  instagramLink: string
  youtubeLink: string
  twitterLink: string
  facebookLink: string
  linkedInLink: string
}

interface CookieData {
  title: string
  text: string
  checked: boolean
  onCheckChanged: (val: boolean) => void
}

const Cookie: React.FC<CookieData> = ({ title, text, checked, onCheckChanged }) => {
  function onChange() {
    onCheckChanged(!checked)
  }

  console.log(`${title} checked ${checked}`)

  return (
    <div className="flex my-4">
      <div className="flex flex-col">
        <p style={{
          fontSize: '15px',
          color: '#333333',
          fontStyle: 'bold',
          fontWeight: '700'
        }}>{title}</p>
        <p className="pt-2" style={{
          fontSize: '14px',
          color: '#333333',
          fontStyle: 'normal',
          fontWeight: '400'
        }}>
          {text}
        </p>
      </div>
      <div className="form-control mt-1">
        <input id={title.toLowerCase().replaceAll(' ', '')} key={title.toLowerCase().replaceAll(' ', '')} type="checkbox"
               className="toggle toggle-accent" onChange={() => onChange()} checked={checked} />
      </div>
    </div>
  )
}

export function CookiesModal() {
  const translator = useTranslation().cookies
  const [checkedCookies, setCheckedCookies] = useState([false, false, false, false])
  const [isOpen, setIsOpen] = useState(false)

  const [showCookies, setShowCookies] = useState(false)

  useEffect(() => {
    let allCookiesAccepted = true
    const val = localStorage.getItem('cookie-1')
    if (val === null || val === '0') {
      allCookiesAccepted = false
    }

    setCheckedCookies([...checkedCookies])
    setIsOpen(!allCookiesAccepted)
  }, [])

  function closeModal() {
    setIsOpen(false)
  }

  function saveCookieStateToStorage(index: number, state: boolean) {
    localStorage.setItem(`cookie${index}`, state ? '1' : '0')
  }

  function changeCookieChecked(val: boolean, index: number) {
    const newCookiesState = checkedCookies
    newCookiesState[index] = val
    setCheckedCookies([...newCookiesState])
  }

  function acceptAllCookies() {
    saveCookieStateToStorage(-1, true)
    for (let i = 0; i < checkedCookies.length; i++) {
      checkedCookies[i] = true
      saveCookieStateToStorage(i, true)
    }
    setCheckedCookies([...checkedCookies])
    setIsOpen(false)
  }

  function acceptSelectedCookies() {
    saveCookieStateToStorage(-1, true)
    for (let i = 0; i < checkedCookies.length; i++) {
      saveCookieStateToStorage(i, checkedCookies[i])
    }
    setCheckedCookies([...checkedCookies])
    setIsOpen(false)
  }

  const cookies = [
    <Cookie
      text={translator.cookie1.text}
      title={translator.cookie1.title}
      checked onCheckChanged={(val: boolean) => {
    }} />,
    <Cookie
      text={translator.cookie2.text}
      title={translator.cookie2.title}
      checked={checkedCookies[0]} onCheckChanged={(val: boolean) => {
      changeCookieChecked(val, 0)
    }}
    />,
    <Cookie
      text={translator.cookie3.text}
      title={translator.cookie3.title}
      checked={checkedCookies[1]} onCheckChanged={(val: boolean) => {
      changeCookieChecked(val, 1)
    }}
    />,
    <Cookie
      text={translator.cookie4.text}
      title={translator.cookie4.title}
      checked={checkedCookies[2]} onCheckChanged={(val: boolean) => {
      changeCookieChecked(val, 2)
    }}
    />,
    <Cookie
      text={translator.cookie5.text}
      title={translator.cookie5.title}
      checked={checkedCookies[3]} onCheckChanged={(val: boolean) => {
      changeCookieChecked(val, 3)
    }}
    />
  ]
  const {lang}=useContext(LangContext)


  return (
    <>
      {isOpen &&
        <Dialog
          className={testStyles.popupCookies}
          style={{ backgroundColor: '#454545ee' }}
          open={isOpen}
          onClose={(_: boolean) => closeModal()}
        >
          <div className="flex flex-col justify-end">
            <Dialog.Overlay />
            <div className="mb-4 mx-4 md:mb-8 md:mx-8 align-middle">
              <div className={styles.cookiesModalContent}>
                <div className="flex flex-col overflow-y-scroll">
                  <div className="flex items-end">
                    <div>

                  <span
                    style={{
                      fontSize: '14px',
                      color: '#333333',
                      fontStyle: 'normal',
                      fontWeight: '400'
                    }}
                    dangerouslySetInnerHTML={{ __html: translator.reason.start }}
                  />
                      <span
                        style={{
                          fontSize: '14px',
                          color: '#79C292',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          textDecoration: 'underline'
                        }}
                        dangerouslySetInnerHTML={{ __html: translator.reason.link1 }}
                        onClick={() => {
                          window.open(lang==='tr'?'Colendi Sigorta_Çerez Politikası_22062022.pdf':'Colendi Sigorta - Cookie Policy.pdf', '_blank')
                        }}
                      />
                      <span
                        style={{
                          fontSize: '14px',
                          color: '#333333',
                          fontStyle: 'normal',
                          fontWeight: '400'
                        }}
                        dangerouslySetInnerHTML={{ __html: translator.reason.and }}
                      />
                      <span
                        style={{
                          fontSize: '14px',
                          color: '#79C292',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          textDecoration: 'underline'
                        }}
                        dangerouslySetInnerHTML={{ __html: translator.reason.link2 }}
                        onClick={() => {
                          window.open(lang==='tr'?'Colendi Sigorta_Gizlilik Politikası_22062022.pdf':'Colendi Sigorta - Privacy Policy.pdf', '_blank')
                        }} />
                      <span
                        style={{
                          fontSize: '14px',
                          color: '#333333',
                          fontStyle: 'normal',
                          fontWeight: '400'
                        }}
                        dangerouslySetInnerHTML={{ __html: translator.reason.end }}
                      />

                    </div>
                  </div>
                  <div className={styles.cookiesHolder}>
                    {showCookies &&
                      cookies.map((cookie) => {
                        return cookie
                      })
                    }
                  </div>
                </div>
                <div className="flex justify-between">
                  {!showCookies &&
                    <button style={{
                      placeSelf: 'start',
                      marginTop: '1.5rem',
                      whiteSpace: 'nowrap',
                      fontSize: '14px',
                      width: '150px',
                      backgroundColor: '#79C292'
                    }}
                            type="button"
                            onClick={acceptAllCookies}
                            className={`${testStyles.btnSubscribe}`}>
                      {translator.acceptAll}
                    </button>
                  }
                  {showCookies &&
                    <button style={{
                      placeSelf: 'start',
                      marginTop: '1.5rem',
                      fontSize: '14px',
                      width: '150px',
                      backgroundColor: '#79C292',
                      whiteSpace: 'nowrap'
                    }}
                            type="button"
                            onClick={acceptSelectedCookies}
                            className={`${testStyles.btnSubscribe}`}>
                      {translator.acceptSelected}
                    </button>
                  }

                  <div className="self-center" style={{ marginTop: '1.5rem' }}>
                  <span role="button" style={{
                    placeSelf: 'start',
                    fontSize: '14px',
                    color: '#79C292',
                    textDecoration: 'underline',
                    fontWeight: '400'
                  }} dangerouslySetInnerHTML={{ __html: showCookies ? translator.close : translator.open }}
                        onClick={() => {
                          setShowCookies(!showCookies)
                        }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      }
    </>
  )
}

const GAid = 'G-Q3MHLCQ3ZD'

const App = (
  {
    Component, pageProps
  }
    : AppProps) => {


  // const footerContext = useContext(FooterContext)
  // const footerRef = useRef() as React.MutableRefObject<HTMLDivElement>
  // const isVisible = useOnScreen(footerRef)
  // useEffect(() => {
  //   console.log(`visible ${isVisible}`)
  //   footerContext.isVisible = isVisible
  // }, [isVisible])


  useEffect(() => {
    // ReactGA.initialize(GAid)
    const handleRouteChange = (url: string) => GTMPageView(url)
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  // Keeps track of open menu panel
  const [navbarMenuId, setNavbarMenuId] = useState<string>('')

  const initialAppContext: AppContextInterface = useMemo(() => {
    return { navbarMenuId, setNavbarMenuId }
  }, [{ navbarMenuId, setNavbarMenuId }])

  // const gtagMeasurementId='G-Q3MHLCQ3ZD';//Local
  const gtagMeasurementId = 'G-3H9GWPSV6K'//Stage

  return <LangProvider>
    <AppContext.Provider value={initialAppContext}>
      <div>
        <NavBar />
      </div>
    </AppContext.Provider>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Script strategy="afterInteractive" async src={`https://www.googletagmanager.com/gtag/js?id=${gtagMeasurementId}`} />

    <Script id="google-analytics" strategy="afterInteractive">
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${gtagMeasurementId}');
      `}
    </Script>
    <Component {...pageProps} />
    <HomepageFooter />
  </LangProvider>
}

export default App
