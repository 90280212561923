function SvgLinkedin() {
  return (
    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="5" fill="white" />
      <g clip-path="url(#clip0_1514_981)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.5 4.5H4.5V19.5H19.5V4.5ZM7.86681 9.15831C8.58547 9.15831 9.16806 8.57572 9.16806 7.85706C9.16806 7.1384 8.58547 6.55582 7.86681 6.55582C7.14816 6.55582 6.56557 7.1384 6.56557 7.85706C6.56557 8.57572 7.14816 9.15831 7.86681 9.15831ZM10.3598 10.12H12.5164V11.1079C12.5164 11.1079 13.1016 9.93748 14.6939 9.93748C16.1143 9.93748 17.2909 10.6372 17.2909 12.77V17.2675H15.0561V13.315C15.0561 12.0568 14.3844 11.9184 13.8725 11.9184C12.8103 11.9184 12.6284 12.8347 12.6284 13.4791V17.2675H10.3598V10.12ZM9.00115 10.12H6.73249V17.2675H9.00115V10.12Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1514_981">
          <rect x="4.5" y="4.5" width="15" height="15" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgLinkedin
